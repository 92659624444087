
const toUtf8 = (data) => {

    /*
    Remember that b64Encode below only works with strings where each character is in the range
    of U+0000 to U+00FF, which is essentially the standard ASCII character set. If you
    need to encode a string with characters outside of this range (like characters in
    different languages or special symbols), you should first encode the string into
    UTF-8. This can be done using encodeURIComponent():
    */

    return encodeURIComponent(data);
};

const utf8ToAscii = (text) => {
  // Decode URI component if necessary
  const decodedText = decodeURIComponent(text);

  // Replace non-ASCII characters with an empty string
  return decodedText.replace(/[^\x00-\x7F]/g, "");
};

const b64Encode = (data) => {
    try
    {
        const decodedText   = decodeURIComponent(data);
        const cleansed      = decodedText.replace(/[^\x00-\x7F]/g, "");

        return btoa(cleansed);
    }
    catch(err)
    {
        console.log('b64Encode error: ', err.message);
        console.log('for data: ', data);
        return 'b64Encode Error';
    }
}

const b64Decode = (encoded) => {
    try {

        if(! encoded)
            return null;

        return atob(encoded);
    }
    catch(err) {
        console.log('b64Decode error: ', err.message);
        return null;
    }
    // return Buffer.from(encoded, 'base64').toString('utf-8');
};

const readUrlParams = () => {
    const currentUrl    = window.location.href;
    const urlParams     = new URLSearchParams(new URL(currentUrl).search);

    const params = {};

    for (const [key, value] of urlParams.entries()) {
        params[key] = value;
    }

    return params;
}

const capitalize = (str) => {
    if (typeof str !== 'string') {
        return '';
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export {
    toUtf8,
    utf8ToAscii,
    b64Encode,
    b64Decode,
    readUrlParams,
    capitalize
}
