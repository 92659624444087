import React                            from 'react'
import { Route, Routes, Navigate }      from 'react-router-dom'

import LandingPage                      from './pages/LandingPage'
import GirlPage                         from './pages/GirlPage'

const AppRoutes = () => (
    <Routes>
        <Route path='/'           element={<LandingPage />} exact />
        <Route path='/nfts/:girl' element={<GirlPage />}    exact />

        <Route path="*"           element={<Navigate to="/" />} />
    </Routes>
)

export default AppRoutes
