import React, { useState, useEffect }   from 'react'
import { HashLink }                     from 'react-router-hash-link'
import Styles                           from './Header.module.css'


const Header = ({ isLoggedIn }) => {
    const [scrolled, setScrolled] = useState(false);

    const handleScroll = () => {
        const vh = window.innerHeight / 100;
        if (window.scrollY > 80 * vh) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
    <header className={`${Styles.header} ${scrolled ? Styles.scrolled : ''}`}>
        <nav className={Styles.menu}>
            <ul className={Styles.leftMenu}>
                <li><HashLink smooth to="/#HeroSection">Home</HashLink></li>
                <li><HashLink smooth to="/#AboutSection">About</HashLink></li>
                <li><HashLink smooth to="/#CatalogSection">Catalog</HashLink></li>
            </ul>

        </nav>
    </header>
    );
};

export default Header
