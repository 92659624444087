import React from 'react'
import styles from './AboutSection.module.css'

const AboutSection = () => {
  return (
    <div id="AboutSection" className={styles.aboutSection}>

      <div className={styles.leftColumn}>
        <h1 className={styles.title}>RazorGirlz </h1>
        <h2 className={styles.subtitle}>NFT Collection</h2>
      </div>

      <div className={styles.rightColumn}>
        <p className={styles.description}>
          RazorGirlz NFTs are more than just digital art; they represent a vibrant fusion of cyberpunk aesthetics and cutting-edge technology.
        </p>
        <p className={styles.description}>
          Each RazorGirl is a unique, meticulously crafted character with its own personality and style, making these NFTs highly sought after by collectors.
        </p>

        <p className={styles.description}>
          Owning a RazorGirlz NFT means being part of an exclusive community, supporting innovative artists, and investing in a digital asset that stands out in the ever-growing world of NFTs.
        </p>
      </div>

    </div>
  );
};

export default AboutSection;
