import React    from 'react'
import styles   from './HeroSection.module.css'

const HeroSection = () => {
    return (
    <>
        <div id="HeroSection" className={styles.heroSection}>
            <div className={styles.content}>
                <h1>RazorGirlZ</h1>
                <h3 className={styles.subtitle}>NFT Collection</h3>
            </div>
        </div>
    </>
    )
}

export default HeroSection
