import React            from 'react'
import { HashLink }     from 'react-router-hash-link'

import SocialMediaLinks from './SocialMediaLinks'
import Styles           from './Footer.module.css'


const Footer = () => {

    return (
    <>

    <footer id="footer" className={Styles.footer}>
        <div className={Styles.container}>
            <div className={Styles.row}>

                <div className={Styles.column}>
                </div>

                <div className={Styles.column}>
                </div>

                <div className={`${Styles.column}`}>

                    <div className={Styles.siteBanner}>
                        <h2>RazorGirlZ</h2>
                        <h3>NFT Collection</h3>
                    </div>

                    <HashLink smooth to="/#HeroSection">
                        <img
                            src="/img/girlz/nailz/michelle.thumb.jpg"
                            alt="RazorGirlZ NFT Collection"
                            className={Styles.starGoddessImage}
                            loading="lazy" />
                    </HashLink>

                    <SocialMediaLinks />
                </div>

                <div className={Styles.column}>
                </div>

                <div className={Styles.column}>
                </div>
            </div>

            <div className={Styles.row}>
                <div className={`${Styles.copyrightMessage} ${Styles.copyrightCol} ${Styles.centeredText}`}>
                    &copy; Copyright 2024
                </div>
            </div>
        </div>
    </footer>
    </>
    );
};

export default Footer;
