import React                from 'react'
import { useParams }        from 'react-router-dom'
import styles               from './GirlPage.module.css'
import razorGirlzData       from '../data/girlz.json'

import { capitalize } from '../utils/utils'

const razorGirlz = razorGirlzData.razorgirlz;



const GirlPage = () => {
  const { girl }  = useParams()
  const nft       = razorGirlz.find(nft => nft.title === capitalize(girl))

  if (! nft) {
    return (<div>NFT not found</div>)
  }

  return (
    <>
      <div id="top"></div>
      <div className={styles.girlPage}>
        <div className={styles.girlPageContainer}>
          <div className={styles.leftColumn}>
            <div className={styles.nftImageContainer}>
              <img src={nft.imageUrl} alt={nft.title} className={styles.nftImage} />
            </div>
          </div>
          <div className={styles.rightColumn}>
            <h2 className={styles.nftTitle}>{nft.title}</h2>
            <p className={styles.nftDescription} dangerouslySetInnerHTML={{__html: nft.description}}></p>
            <a href={nft.openseaLink} className={styles.openseaLink} target="_blank" rel="noopener noreferrer external nofollow">
              View on OpenSea
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default GirlPage
