
import React            from 'react'
import AboutSection     from '../sections/AboutSection'
import CatalogSection   from '../sections/CatalogSection'
import HeroSection      from '../sections/HeroSection'

const LandingPage = () => {
    return (
    <>
        <div id="top"></div>

        <div className="page">
            <HeroSection />
            <AboutSection />
            <CatalogSection />
        </div>
    </>
    );
}

export default LandingPage
