import React, { useState }  from 'react'
import { HashLink }         from 'react-router-hash-link'
import styles               from './CatalogSection.module.css'
import razorGirlzData       from '../data/girlz.json'


const itemsPerPage  = 3;
const razorGirlz    = razorGirlzData.razorgirlz;


const CatalogSection = () => {

  const [page, setPage] = useState(0);
  const startIndex      = page * itemsPerPage;
  const endIndex        = startIndex + itemsPerPage;
  const currentItems    = razorGirlz.slice(startIndex, endIndex);


  const handlePrevious = () => {
    setPage(prevPage => Math.max(prevPage - 1, 0));
  };

  const handleNext = () => {
    setPage(prevPage => {
      const maxPage = Math.ceil(razorGirlz.length / itemsPerPage) - 1;
      return Math.min(prevPage + 1, maxPage);
    });
  };


  return (
    <>
    <div id="CatalogSection" className={styles.catalogSection}>

      <div className={styles.catlogTitle}>
        <h1>RazorGirlz</h1>
        <h2>NFT Catalog</h2>
      </div>

      <div className={styles.catalogGrid}>
        {currentItems.map(nft => (
          <div key={nft.id} className={styles.nftThumbnail}>
            <HashLink smooth to={`/nfts/${nft.title.toLowerCase()}#top`}>
              <img src={nft.thumbUrl} alt={nft.title} loading="lazy"/>
            </HashLink>
            <p>{nft.title}</p>
          </div>
        ))}
      </div>

      <div className={styles.paginationControls}>
        <button
          className={`${styles.button} ${page === 0 ? styles.disabled : ''}`}
          onClick={handlePrevious}
          disabled={page === 0} >
          Previous
        </button>

        <button
          className={`${styles.button} ${endIndex >= razorGirlz.length ? styles.disabled : ''}`}
          onClick={handleNext}
          disabled={endIndex >= razorGirlz.length} >
          Next
        </button>
      </div>
    </div>

    </>
  );
};

export default CatalogSection
